import aliyah from '../assets/colleges/ALIYAH COLLEGE OF NURSING.jpeg';
import aj from '../assets/colleges/AJ GROUP OF COLLEGES.jpg';
import indira from '../assets/colleges/INDIRA COLLEGE.jpg';
import manglore from '../assets/colleges/MANGALORE INSTITUTE.jpg';
import masood from '../assets/masoodcg.jpg';
import milagres from '../assets/colleges/MILAGRES COLLEGE.jpg';
import moodlakatte from '../assets/colleges/MOODLAKATTE COLLEGE OF NURSING.jpg';
import sahayadri from '../assets/colleges/SAHAYADRI COLLEGE.jpg';
import SRIDEVI from '../assets/colleges/SRIDEVI.jpg';
import THEJASVINI from '../assets/tejas.jpg';
import udupi from '../assets/colleges/UDUPI GROUP OF COLLEGES.jpg';
import vidyarathna from '../assets/colleges/VIDYARATHNA INSTITUTE OF HEALTH SCIENCES..jpg';
import YENEPOYA from '../assets/colleges/YENEPOYA.jpg';
import mv from '../assets/mvstetty.jpg';
import srinivas from '../assets/colleges/SRINIVAS GROUP.png';
import karavali from '../assets/karavali-COLLEGE-PHOTO-FINAL1.jpg';
import city from '../assets/city.jpg';
import pa from '../assets/pa.jpg';
import nitte from '../assets/nitte.jpg';
import kanachur from '../assets/kanchur.jpg';
import mangala from '../assets/mangala.jpg';
import scs from '../assets/colleges/SCS-College-of-Nursing-Sciences-Mangalore.jpg'

export const colleges = [
  {
    id: 1,
    name: 'SCS GROUP OF INSTITUTIONS',
    sub_title: 'SCS GROUP OF INSTITUTIONS',
    description:
      'Established in the year 1994 with an aim to impart education in medical & paramedical field, Karnataka Educational & Charitable Trust is well known name in the field of education. The trust offers training in Ayurveda, Nursing and Pre Schools with a world class infrastructure and trained faculty.',
    img_url: scs,
  },
  {
    id: 2,
    name: 'KARAVALI GROUP',
    sub_title: 'KARAVALI GROUP OF INSTITUTIONS',
    description:
      'Karavali Institute of Technology (KIT) is a premier institute established by the G R Education Trust is situated in picturesque region at Mangalore, Karnataka.',
    img_url: karavali,
  },
  {
    id: 3,
    name: 'YENEPOYA ',
    sub_title: 'DEEMED TO BE UNIVERSITY',
    description:
      "Yenepoya Institute of Technology was established in 2008 with the monumental objective of promoting academic excellence and competence in students, especially in the fast-growing global domain of Engineering Technology and Management. The Institute made remarkable progress since it's inception in every aspect and gained name among the Institutes of Technology in the state of Karnataka due to the quality of education and training provided by its dedicated faculty and the infrastructure available. This renowned Institute is now owned by the Islamic Academy of Education of Yenepoya Group of Institutions and Yenepoya University An organization well known for Quality Education.",
    img_url: YENEPOYA,
  },
  {
    id: 4,
    name: 'SRINIVAS GROUP',
    sub_title: 'SRINIVAS GROUP OF INSTITUTIONS',
    description:
      'Srinivas Group of Institutions, formerly Srinivas Group of Colleges, runs educational institutes in and around Mangalore, Karnataka, India. The Colleges are managed by A. Shama Rao Foundation, which is an Educational and Public Charitable Registered Trust. It was founded by Raghavendra Rao',
    img_url: srinivas,
  },
  {
    id: 5,
    name: 'AJ GROUP OF COLLEGES',
    sub_title: 'NAAC Accredited',
    description:
      'The promoting body of all the Medical & Para Medical Instituitions is Laxmi Memorial Education Trust (R.).The Trust being a Family Trust was registered in the year 1991 in the memory of Late. Laxmi Shetty, mother of Mr. A.J Shetty, who is the President and Managing Director of the Trust. The Trust is accorded the recognition of linguistic minority trust by the Government of Karnataka.',
    img_url: aj,
  },
  {
    id: 6,
    name: 'SRIDEVI GROUP',
    sub_title: 'SRIDEVI GROUP OF COLLEGES',
    description:
      'The Shree Devi Education Trust is a respected name in the field of academic endeavor in the coastal districts of Karnataka, India. Established in 1992, it was founded by a team of enlightened educationalists with a vision to provide quality education to enable the youth to become efficient professionals as well as upright citizens. The Founder Chairman of the Trust, Sri A. Sadananda Shetty, is a recipient of the Dakshina Kannada Rajyotsava award.',
    img_url: SRIDEVI,
  },
  {
    id: 7,
    name: 'NITTE University',
    sub_title: 'Mangalore',
    description:
      'With 3 campuses, 43 institutions, 130 courses, 4500 faculty, 25000 students, vibrant placement cells, collaborations with universities abroad, Nitte is a destination of choice for students from within the country and abroad. In education for over 40 years now, Nitte was awarded the Deemed to be University status in 2008, has been accredited with "A+" by NAAC, and is ranked 75th in NIRF 2022.',
    img_url: nitte,
  },
  {
    id: 8,
    name: 'Kanachur Institute of Medical',
    sub_title: 'Mangalore',
    description:
      'Overview of Kanachur Institute of Medical Sciences Deralakatte (KIMS)Kanachur Institute of Medical Sciences(KIMS) stands strikingly as an architectural beauty, with its massive structures and aesthetic view leaves every one spell bound. Strategically located away from the hustle bustle of city traffic, the ambient atmosphere provides an ideal learning centre for students across the globe. With its impressive infrastructure backed by the state-of-the-art infrastructure',
    img_url: kanachur,
  },
  {
    id: 9,
    name: 'New Mangala College of Nursing',
    sub_title: 'Mangalore',
    description:
      'New Mangala College of Nursing under the management of Mangala Charitable Trust® Mangaluru is situated at Vidyanagar, Post Pedamale, Neermarga, Mangaluru – 575029, a place well connected by bus routes to all destinations.',
    img_url: mangala,
  },
  {
    id: 10,
    name: 'MV SHETTY COLLEGE',
    sub_title: 'Mangalore',
    description:
      'Late Dr. M.V. Shetty, MS, FRCS, FRFPS was eminent in several fields ; in his professional field of surgery, in rehabilitation and in the state - run institution of Home Guards. As a surgeon with the 1st private Surgical Specialty Hospital in Dakshina Kannada District, he was also distinguished Professor of Surgery at KMC, Honorary Medical Officer at Government Wenlock Hospital, Director of the Lions Limb Centre and Commandant of the D.K. Home Guards. His dedication earned him the respect of patients, colleagues, fellow doctors, students and the general public. He had the courage of his strong convictions and a humane code of practice to match. At the personal level he was known to have a keen appreciation of music, he played the tabla and young people, his students especially, always responded to his humorous and understanding way of dealing with them.',
    img_url: mv,
  },
  {
    id: 11,
    name: 'MILAGRES COLLEGE',
    sub_title: 'Affiliated to Mangalore University and recognized by UGC MHRD',
    description:
      'Milagres Educational Institution was founded by Bishop Rt Rev. Bernardin in 1848. In the beginning, it was started as a Primary English School with classes I to V and higher classes were added gradually. The Higher Secondary school was started on July 23, 1929. The Government of Karnataka upgraded the High School into Pre-University College on July 5, 1982.',
    img_url: milagres,
  },
  {
    id: 12,
    name: 'MANGALORE INSTITUTE OF TECHNOLOGY ',
    sub_title: 'Mangalore',
    description:
      'Mangalore Institute of Technology & Engineering (MITE), a leading Engineering & Management Institution in the region is established by the Rajalaxmi Education Trust under the leadership of the Visionary Mr. Rajesh Chouta in 2007. The natural aura of the beautiful serene, green campus coupled by its passion to provide the best for students to learn, explore and has truly created a powerful atmosphere for everyone to nurture their skills and excel. MITE shapes professionals who are an optimum mix of right attitude, skill, culture and knowledge.',
    img_url: manglore,
  },
  {
    id: 13,
    name: 'SAHAYADRI COLLEGE',
    sub_title: 'Approved by AICTE New Delhi and Affiliated by VTU Belgaum.',
    description:
      'The eco-friendly picturesque campus is situated on the banks of the River Nethravathi and is adjacent to Mangaluru - Bengaluru National Highway NH 75. The Campus is located at a distance of 8 km from the port city of Mangaluru, a popular education hub. It is well connected by road, air and train to all the major cities of India.',
    img_url: sahayadri,
  },
  {
    id: 14,
    name: 'UDUPI GROUP OF COLLEGES',
    sub_title: 'Approved by INC & KNC',
    description:
      'The institution succeeded in establishing five professional colleges approved by Government of Karnataka, namely Udupi College of Professional Studies, Manipal (Affiliated to Mangalore University), Udupi College of Nursing (Affiliated to Rajiv Gandhi University of Health Sciences Bengaluru, approved by INC & KNC), Udupi School of Nursing (Recognized by Karnataka State Diploma in Nursing Examination Board Bengaluru), Udupi College of Physiotherapy (Affiliated to Rajiv Gandhi University of Health Sciences, Bengaluru) and Udupi College of Allied Health Sciences (Affiliated to Rajiv Gandhi University of Health Sciences, Bengaluru).',
    img_url: udupi,
  },
  {
    id: 15,
    name: 'VIDYARATHNA INSTITUTE OF HEALTH SCIENCES',
    sub_title: 'UDUPI COLLEGE',
    description:
      'Vidyarathna College of Nursing is situated in Udupi in Karnataka state of India. Established in 2005, it is accredited from AICTE and it is affiliated to Rajiv Gandhi University. VCN, Udupi offers 2 courses across 1 streams namely Nursing and across 2 degrees like GNM, BSc.Hostel facility is not available for its students. Additional campus facilities such as Boys Hostel, Canteen, Computer Lab, Hospital, Library, Medical Facilities, Cls. Room are also there.',
    img_url: vidyarathna,
  },
  {
    id: 16,
    name: 'MOODLAKATTE COLLEGE OF NURSING',
    sub_title: 'KUNDAPURA',
    description:
      'MCN offers graduate nursing courses with the objective of training strong student force who will become the torch bearers of the noble profession. These courses aim to support the students’ ideas, ethics, and conscience to create nurses of outstanding qualities, who are well aware of the human dimensions in this noble profession.',
    img_url: moodlakatte,
  },
  {
    id: 17,
    name: 'MASOOD COLLEGE OF NURSING',
    sub_title: 'Mangalore',
    description:
      'Masood Educational & Charitable Trust ® (MECT) was founded in 2003-2004 and are presently conducts General Nursing and Midwifery, Basic B.Sc. Nursing, P.B.B.Sc. and M.Sc. Nursing courses. Our Institution, attempts to mould well-motivated young minds, to take on the daunting responsibility with grace and confidence, equipping them not just with knowledge that will help them assist doctors during critical or surgical operations but also with the sensitivity and gentleness required to administer efficient bed side care.',
    img_url: masood,
  },
  {
    id: 18,
    name: 'ALIYAH COLLEGE OF NURSING',
    sub_title: 'Mangalore',
    description:
      'Aaliyah College of Nursing Mangalore is one of the popular colleges in the state of Karnataka. It is one of the leading colleges in Medicine and Health Sciences. It is located near, Abbakka Cir, Ullal, Mangalore, Karnataka.',
    img_url: aliyah,
  },
  {
    id: 19,
    name: 'P. A. College of Engineering',
    sub_title: 'Mangalore',
    description:
      'P. A. First Grade College is affiliated to the Mangalore University, Mangalagangothri, Dakshina Kannada, Karnataka and has been recognized by the University Grants Commission since a long time. This college is counted among the top-rated colleges in Karnataka with an excellent scholastic and co-scholastic track record.',
    img_url: pa,
  },
  {
    id: 20,
    name: 'INDIRA COLLEGE',
    sub_title: 'Mangalore',
    description:
      'Indira Education Trust (R) was established on 20th May 2003. The office is located at Indira Hospital Annexe, Mother Therassa Road,Falnir, Mangalore -575 002',
    img_url: indira,
  },
  {
    id: 21,
    name: 'THEJASVINI',
    sub_title: 'Nursing Institute',
    description:
      'Tejasvini Nursing Institute is located in Kudupu, 6 K.M. from the railway station on the national highway 169, opposite to the famous Anantha Padmanabha Temple kudupu is housed in our 45000 sq. ft. area building on the top of Kudupu hills in 7 acre land overlooking the Arabian sea & western ghats on the other side.    ',
    img_url: THEJASVINI,
  },
  {
    id: 22,
    name: 'CITY COLLEGE',
    sub_title: 'INC (Indian Nursing Council) approved',
    description:
      'City College provides a friendly and stimulating environment for learning. On offer are a wide choice of undergraduate courses in Arts, Science and Commerce. At present, 14 subjects are taught at the Honours level. City College draws its academic strength from not only a very rich faculty, but also an excellent library, which received special praise from the NAAC (National Assessment and Accreditation Council) peer team during its visit to our College. Seminars, workshops and projects further liven up and enrich the academic ambience.',
    img_url: city,
  },
];
