import React, { useState } from 'react';

const ContactUs = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const { name, email, message } = data;
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!name.trim() || !email.trim() || !message.trim()) {
      setError(true);
      return;
    }
    try {
      await fetch('https://v1.nocodeapi.com/smecweb/google_sheets/gtkHiBrCENEeKrEu?tabId=Sheet1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([[name, email, message]]),
      });
      setData({
        name: '',
        email: '',
        message: '',
      });
      setError(false);
      setSuccess('Form submitted successfully');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div class='contact-us-wrapper single-page-section-top-space single-page-section-bottom-space'>
      <div class='breadcrumb-wrap style-01'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='breadcrumb-content'>
                <h3 class='title'>Contact us</h3>
                <p class='details'>
                  We have professional alliance's with leading Universities and Colleges around
                  Karnataka.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class='contact-form-area-wrapper section-bottom-space'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='apply-form-inner'>
                <div class='row justify-content-between'>
                  <div class='col-lg-5'>
                    <div class='contact-address'>
                      <h3 class='title'>Get in touch</h3>

                      <ul class='ul contact-address-list'>
                        <li class='single-address-item'>
                          <span class='icon-wrap color-01'>
                            <i class='fa-sharp fa-solid fa-location-dot icon'></i>
                          </span>
                          <span class='text'>
                            {/* Door No: 3-E-16-1370/2(1) <br /> Shop Number: B4 <br />
                            Address: Prasasha Building,
                            <br /> Kadri Tollgate,
                            <br /> Mangalore -575002 */}
                             Shop Number: 34
                        <br/>Address: 3rd floor, City Ligh <br/>Building, Next to Mandovi <br/>Motors, Milagris, Hampankatta, <br/>Manglore - 575001
                          </span>
                        </li>
                        <li class='single-address-item'>
                          <span class='icon-wrap color-02'>
                            <i class='fa-solid fa-phone icon'></i>
                          </span>
                          <span class='text'>
                            (+91) 8904293697 <br />
                            08243575400
                          </span>
                        </li>
                        <li class='single-address-item'>
                          <span class='icon-wrap color-03'>
                            <i class='fa-solid fa-envelope-open icon'></i>
                          </span>
                          <span class='text'>
                            smec@smart-root.com <br />
                            {/* headoffice@example.com */}
                          </span>
                        </li>
                      </ul>

                      {/* <ul class='ul social-media-list style-01 color-02'>
                        <li class='single-social-item'>
                          <a href='#' tabindex='-1'>
                            <i class='fa-brands fa-instagram icon'></i>
                          </a>
                        </li>
                        <li class='single-social-item'>
                          <a href='#' tabindex='-1'>
                            <i class='fa-brands fa-facebook-f icon'></i>
                          </a>
                        </li>
                        <li class='single-social-item'>
                          <a href='#' tabindex='-1'>
                            <i class='fa-brands fa-youtube icon'></i>
                          </a>
                        </li>
                        <li class='single-social-item'>
                          <a href='#' tabindex='-1'>
                            <i class='fa-brands fa-linkedin-in icon'></i>
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <div class='col-lg-7'>
                    <div class='contact-form'>
                      <form class='form' onSubmit={handleSubmit}>
                        <div class='part'>
                          <h5 class='title'>Primary Information</h5>
                          <div class='form-element'>
                            <div class='row'>
                              <div class='col-lg-6'>
                                <i class='fa-solid fa-user'></i>
                                <input
                                  type='text'
                                  placeholder='Full name'
                                  name='name'
                                  required=''
                                  value={name}
                                  onChange={handleChange}
                                />
                              </div>
                              <div class='col-lg-6'>
                                <i class='fa-solid fa-envelope-open'></i>
                                <input
                                  type='email'
                                  placeholder='Email address'
                                  required=''
                                  name='email'
                                  value={email}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div class='form-element'>
                            <div class='row'>
                              <div class='col-lg-6'>
                                <i class='fa-solid fa-phone'></i>
                                <input
                                  type='number'
                                  placeholder='Phone number'
                                  required=''
                                  name='message'
                                  value={message}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='form-submit text-right'>
                          <button type='submit' class='btn-common btn-active'>
                            submit Message
                          </button>
                          {error && <div style={{ color: 'red' }}>Please fill in all fields</div>}
                          {success && (
                            <div style={{ color: 'green' }}>
                              Your details has been submitted Thank you
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div class='map-wrapper section-top-space'>
      <iframe
        class='w-100'
        src='https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d116838.78142003674!2d90.35554992826201!3d23.775466805757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3755c74493e3ad33%3A0xd8d84b987eddb825!2s199%2C%20Venus%20Complex%2C%2012th%20Floor%20Kha-199%2F2%2C%204%20Bir%20Uttam%20Rafiqul%20Islam%20Ave%2C%20Dhaka%201212!3m2!1d23.7754826!2d90.4255903!5e0!3m2!1sen!2sbd!4v1663127596682!5m2!1sen!2sbd'
        height='510'
        style={{ border: '0' }}
        allowfullscreen=''
        loading='lazy'
        referrerpolicy='no-referrer-when-downgrade'
      ></iframe>
    </div> */}
    </div>
  );
};

export default ContactUs;
