import React from 'react';
import { useNavigate } from 'react-router-dom';
import AboutUsImg from '../assets/course-3.jpg';
import key from '../assets/img/icon/icon-and-text/key.svg';
import aboutus from '../assets/pexels-oladimeji-ajegbile-2325729.jpg';
import whyus from '../assets/why-us-new.png';
import board from '../assets/img/icon/icon-and-text/board.svg';
import monitor from '../assets/img/icon/icon-and-text/monitor.svg';
import one from '../assets/img/about/01.jpg';
import peopleGroup from '../assets/img/sections/client/people-group.png';
import customerCare from '../assets/img/sections/client/customer-care.png';
import graduation from '../assets/img/sections/client/graduation.png';
import world from '../assets/img/sections/client/world.png';
import iconTextOne from '../assets/img/icon/icon-and-text/02/01.png';
import iconTextTwo from '../assets/img/icon/icon-and-text/02/02.png';
import iconTextThree from '../assets/img/icon/icon-and-text/02/03.png';
import { Link } from 'react-router-dom';
import './Aboutus.scss';
const AboutUs = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };
  return (
    <div class='about-page-area-wrapper single-page-section-top-space '>
      <section class='about-section-area section-bottom-space'>
        <div class='container custom-container-01'>
          <div class='row align-items-center'>
            <div class='col-lg-6 col-md-12'>
              <div class='about-content'>
                <h3 class='content-title'>About us</h3>

                <p class='paragraph'>
                  SmartRoot Manglore Education Consultancy is one of the renowned education
                  consultancy firms which assisting students from Karnataka
                </p>
                <p class='paragraph'>
                  The education consultancy in Mangalore helps students and their families navigate
                  the complex world of higher education. They offer college and university
                  counseling, application support, financial aid advice and other services,
                  including test preparation courses, career counseling, and language classes. The
                  team of experienced professionals is passionate about student success and offers
                  tailored support to help students reach their full potential. With a focus on
                  student success, the education consultancy is a valuable resource for those
                  seeking higher education in Mangalore.
                </p>

                <div class='icon-box-with-text-wrap'>
                  <ul class='ul icon-box-with-text style-01'>
                    <li class='single-icon-box-with-text'>
                      <div class='icon-wrap color-01'>
                        <img src={key} alt='' />
                      </div>
                      <div class='content'>
                        <p class='text'>
                          The education consultancy offers a wide range of counseling services,
                          including college and university counseling, application support, and
                          financial aid advice, to help students make informed decisions about their
                          education.
                        </p>
                      </div>
                    </li>
                    <li class='single-icon-box-with-text style-02'>
                      <div class='icon-wrap color-02'>
                        <img src={board} alt='' />
                      </div>
                      <div class='content'>
                        <p class='text'>
                          The education consultancy has a team of experienced and knowledgeable
                          professionals who have a passion for helping students achieve their full
                          potential. They work closely with students to understand their unique
                          needs and goals and provide personalized guidance to help them reach their
                          full potential.
                        </p>
                      </div>
                    </li>
                    <li class='single-icon-box-with-text style-03'>
                      <div class='icon-wrap color-03'>
                        <img src={monitor} alt='' />
                      </div>
                      <div class='content'>
                        <p class='text'>
                          The education consultancy is dedicated to helping students achieve their
                          dreams and reach their full potential. With a focus on student success,
                          the education consultancy offers a variety of services, including test
                          preparation courses, career counseling, and language classes, to help
                          students reach their full potential.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class='btn-wrap' onClick={handleClick}>
                  <a href='#' class='btn-common btn-new'>
                    Get Free Consultation
                  </a>
                </div>
              </div>
            </div>
            <div class='col-lg-6 col-md-12'>
              <div class='thumbnail '>
                <div class='right-frame'>
                  <img src={aboutus} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about area end here  -->

        <!-- our team area start here  --> */}

      {/* <!-- our team area end here  -->

        <!-- counter area start here --> */}
      <section class='counter-area-wrapper'>
        <div class='counter-section-area'>
          <div class='container custom-container-01'>
            <div class='row'>
              <div class='col-lg-12'>
                <div class='counter-section-inner style-01'>
                  <div class='single-counterup'>
                    <div class='image-wrap'>
                      <img src={peopleGroup} alt='' />
                    </div>
                    <div class='content-wrap'>
                      <div class='odo-area'>
                        <h3 class='odometer odo-title' data-odometer-final='458712'>
                          1000+
                        </h3>
                      </div>
                      <div class='content'>
                        <h6 class='subtitle'>More then students</h6>
                      </div>
                    </div>
                  </div>
                  <div class='single-counterup'>
                    <div class='image-wrap'>
                      <img src={customerCare} alt='' />
                    </div>
                    <div class='content-wrap'>
                      <div class='odo-area'>
                        <h3 class='odometer odo-title' data-odometer-final='211'>
                          100+
                        </h3>
                      </div>
                      <div class='content'>
                        <h6 class='subtitle'>Total consultants</h6>
                      </div>
                    </div>
                  </div>
                  <div class='single-counterup'>
                    <div class='image-wrap'>
                      <img src={graduation} alt='' />
                    </div>
                    <div class='content-wrap'>
                      <div class='odo-area'>
                        <h3 class='odometer odo-title' data-odometer-final='425'>
                          100+
                        </h3>
                      </div>
                      <div class='content'>
                        <h6 class='subtitle'>Total courses</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- counter area end here -->

        <!-- chose us area start here  --> */}
      <section class='chose-area-wrapper '>
        <div class='chose-area-inner bg-color-01'>
          <div
            class='bg-image background-image'
            style={{ 'background-image': 'url(assets/img/chose-us/01.jpg)' }}
          >
            <img src={whyus} />
          </div>
          <div class='container custom-container-01'>
            <div class='row justify-content-end'>
              <div class='col-lg-6'>
                <div class='img-box'></div>
              </div>
              <div class='col-lg-6'>
                <div class='content-wrap'>
                  <div class='section-title-wrapper'>
                    <h4 class='section-title'>Why chose us</h4>
                    <p class='description color-02'>
                      87% of people learning for professional development report career benefits
                    </p>
                  </div>

                  <div class='icon-box-with-text-wrap'>
                    <ul class='ul icon-box-with-text style-02'>
                      <li class='single-icon-box-with-text'>
                        <div class='icon-wrap'>
                          <img src={iconTextOne} alt='' />
                        </div>
                        <div class='content'>
                          <h4 class='title'>Our Vision</h4>
                          <p class='paragraph'>
                            Our vision is to be the leading student consultancy and recruiting
                            agency of the world by satisfying our clients’ in all aspects.
                          </p>
                        </div>
                      </li>
                      <li class='single-icon-box-with-text style-02'>
                        <div class='icon-wrap'>
                          <img src={iconTextTwo} alt='' />
                        </div>
                        <div class='content'>
                          <h4 class='title'>Our Mission</h4>
                          <p class='paragraph'>
                            BSB mission is to achieve excellence in business through excellent
                            services and new scopes for world-class education by realizing dreams of
                            our clients within their capacities.
                          </p>
                        </div>
                      </li>
                      <li class='single-icon-box-with-text style-03'>
                        <div class='icon-wrap'>
                          <img src={iconTextThree} alt='' />
                        </div>
                        <div class='content'>
                          <h4 class='title'>Our Goal</h4>
                          <p class='paragraph'>
                            Create opportunities of abroad higher study and improve significantly
                            family & position and national development and to contribute to achieve
                            Bangladesh as a middle income country.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- chose us area end here  -->

        <!-- testimonial area start here  --> */}
      {/* <section class='testimonial-area-wrapper section-top-space'>
        <div class='container custom-container-01'>
          <div class='row justify-content-center'>
            <div class='col-lg-6'>
              <div class='section-title-wrapper text-center'>
                <h5 class='subtitle'>SERVICES</h5>
                <h4 class='section-title'>Students who fly abroad</h4>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-lg-12'>
              <div
                class='slick-main global-slick-init'
                data-infinite='true'
                data-arrows='true'
                data-fade='false'
                data-speed='500'
                data-dots='false'
                data-slidesToShow='3'
                data-slidesToScroll='1'
                data-swipeToSlide='true'
                data-autoplay='true'
                data-autoplaySpeed='2500'
                data-responsive='[
                            {"breakpoint": 1367,"settings": {"slidesToShow": 3}},
                            {"breakpoint": 992,"settings": {"slidesToShow": 2}},
                            {"breakpoint": 600, "settings": {"slidesToShow": 1}}
                            ]'
              >
                <div class='slick-item'>
                  <div class='testimonial-single-items style-01 v-02'>
                    <div class='feedback-wrap'>
                      <ul class='ul feedback-icon-list'>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                      </ul>
                    </div>

                    <div class='content'>
                      <p class='feedback-text'>
                        “I able to prove potential employers that i have a solid understanding of
                        computers & hardware- and started to receive real, viable job offers”.
                      </p>
                    </div>

                    <div class='client-and-quote'>
                      <div class='client-details'>
                        <div class='thumb'>
                          <img src='assets/img/sections/testimonial/tesi-01.png' alt='' />
                        </div>
                        <div class='content'>
                          <p class='client-name'>Savannah Nguyen</p>
                          <p class='designation'>Central African Republic</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='slick-item'>
                  <div class='testimonial-single-items style-01 v-02'>
                    <div class='feedback-wrap'>
                      <ul class='ul feedback-icon-list'>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                      </ul>
                    </div>

                    <div class='content'>
                      <p class='feedback-text'>
                        “I able to prove potential employers that i have a solid understanding of
                        computers & hardware- and started to receive real, viable job offers”.
                      </p>
                    </div>

                    <div class='client-and-quote'>
                      <div class='client-details'>
                        <div class='thumb'>
                          <img src='assets/img/sections/testimonial/testi-02.png' alt='' />
                        </div>
                        <div class='content'>
                          <p class='client-name'>Kathryn Murphy</p>
                          <p class='designation'>Monaco</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='slick-item'>
                  <div class='testimonial-single-items style-01 v-02'>
                    <div class='feedback-wrap'>
                      <ul class='ul feedback-icon-list'>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                      </ul>
                    </div>

                    <div class='content'>
                      <p class='feedback-text'>
                        “I able to prove potential employers that i have a solid understanding of
                        computers & hardware- and started to receive real, viable job offers”.
                      </p>
                    </div>

                    <div class='client-and-quote'>
                      <div class='client-details'>
                        <div class='thumb'>
                          <img src='assets/img/sections/testimonial/testi-03.png' alt='' />
                        </div>
                        <div class='content'>
                          <p class='client-name'>Kristin Watson</p>
                          <p class='designation'>Guinea</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='slick-item'>
                  <div class='testimonial-single-items style-01 v-02'>
                    <div class='feedback-wrap'>
                      <ul class='ul feedback-icon-list'>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                        <li class='single-feedback-item'>
                          <i class='fas fa-star icon'></i>
                        </li>
                      </ul>
                    </div>

                    <div class='content'>
                      <p class='feedback-text'>
                        “I able to prove potential employers that i have a solid understanding of
                        computers & hardware- and started to receive real, viable job offers”.
                      </p>
                    </div>

                    <div class='client-and-quote'>
                      <div class='client-details'>
                        <div class='thumb'>
                          <img src='assets/img/sections/testimonial/testi-02.png' alt='' />
                        </div>
                        <div class='content'>
                          <p class='client-name'>Savannah Nguyen</p>
                          <p class='designation'>Central African Republic</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default AboutUs;
