import React from 'react';
import { useNavigate } from 'react-router-dom';
import ellipse from '../assets/img/shapes/Ellipse-01.png';
import ellipse2 from '../assets/img/shapes/Ellipse-02.png';
import vector from '../assets/img/shapes/Vector-15.png';
import plane from '../assets/img/header/plane.png';
import location from '../assets/img/icon/location.png';
import student from '../assets/img/header/header-img.png';
import foresty from '../assets/img/sections/desipline/foresty.png';
import science from '../assets/img/sections/desipline/science.png';
import art from '../assets/img/sections/desipline/art.png';
import business from '../assets/img/sections/desipline/business.png';
import pc from '../assets/img/sections/desipline/pc.png';
import book from '../assets/img/sections/desipline/book.png';
import click from '../assets/img/sections/desipline/click.png';
import french from '../assets/img/sections/desipline/french-fry.png';
import news from '../assets/img/sections/desipline/news-paper.png';
import firstAid from '../assets/img/sections/desipline/first-aid-kit.png';
import law from '../assets/img/sections/desipline/law.png';
import enverment from '../assets/img/sections/desipline/enverment.png';
import humanity from '../assets/img/sections/desipline/humanity.png';
import recicle from '../assets/img/sections/desipline/recicle.png';
import stepOne from '../assets/img/icon/step-01.png';
import stepTwo from '../assets/img/icon/step-02.png';
import stepThree from '../assets/img/icon/step-03.png';
import stepFour from '../assets/img/icon/step-04.png';
import stepFive from '../assets/img/icon/step-05.png';
import { Link } from 'react-router-dom';
import idea from '../assets/img/icon/idea.png';
import coversation from '../assets/img/icon/coversation.png';
import emergency from '../assets/img/icon/emergency.png';
import aboutus from '../assets/pexels-oladimeji-ajegbile-2325729.jpg';

const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };
  return (
    <>
      <section class='banner-area home-01'>
        <div class='container custom-container-01'>
          <div class='banner-wrapper'>
            <div class='row'>
              <div class='col-xl-7 col-lg-10'>
                <div class='banner-inner'>
                  <p class='subtitle'>SPECIAL OFFER FIRST CUSTOMER</p>
                  <h1 class='title'>
                    Navigating the world of education, made <span>simple</span>.
                  </h1>
                  <p>
                    SmartRoot Manglore Education Consultancy Can Fulfil Your Education Dream <br />{' '}
                    As Per Your Best Fit with Karnataka's top universities and collages.
                  </p>
                  <div class='header-btn'>
                    <div class='btn-wrap' onClick={handleClick}>
                      <a class='btn-common flat-btn btn-active'>Contact us</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-xl-5'>
                <div class='thumbnail'>
                  <img src={ellipse} class='element-01' alt='Ellipse' />
                  <img src={ellipse2} class='element-02' alt='Ellipse' />
                  <img src={vector} class='element-03' alt='vector' />
                  <img src={plane} class='element-04' alt='plane' />
                  <img src={location} class='element-05' alt='location' />
                  <img src={student} class='banner-img' alt='Student' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='features-section margin-top-100'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-4 col-md-6'>
              <div class='icon-box-item'>
                <div class='icon'>
                  <img src={idea} alt='' />
                </div>
                <div class='content'>
                  <h4 class='title'>One Stop Study Solution</h4>
                  <p>
                    Are you a student looking to pursue higher education, but overwhelmed by the
                    endless options and requirements? Or are you a parent trying to navigate the
                    complex process of college admissions for your child? Look no further than our
                    educational consultancy - your one-stop solution for all your higher education
                    needs.
                  </p>
                </div>
                <div class='btn-wrap'>
                  <a href='#0' class='more-btn'>
                    {/* Learn More <i class='fa-solid fa-angle-right icon'></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div class='col-lg-4 col-md-6'>
              <div class='icon-box-item'>
                <div class='icon'>
                  <img src={coversation} alt='' />
                </div>
                <div class='content'>
                  <h4 class='title'>One To One Discussion</h4>
                  <p>
                    One-on-one discussions are an essential component of an educational consultancy
                    as they allow for a more personalized approach to college admissions guidance.
                    By meeting with students and parents individually, consultants can provide
                    tailored advice that takes into account each student's unique strengths,
                    interests, and goals.
                  </p>
                </div>
                <div class='btn-wrap'>
                  <a href='#0' class='more-btn'>
                    {/* Learn More <i class='fa-solid fa-angle-right icon'></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div class='col-lg-4 col-md-6'>
              <div class='icon-box-item'>
                <div class='icon'>
                  <img src={emergency} alt='' />
                </div>
                <div class='content'>
                  <h4 class='title'>End To End Support</h4>
                  <p>
                    End-to-end support typically includes a range of services, such as college
                    selection, application assistance, test preparation, financial aid guidance, and
                    career counseling. The consultancy will work with the student or parent to
                    understand their individual needs and provide tailored advice at each step of
                    the process and you can get end to end support.
                  </p>
                </div>
                <div class='btn-wrap'>
                  <a href='#0' class='more-btn'>
                    {/* Learn More <i class='fa-solid fa-angle-right icon'></i> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='about-section-area section-top-space about-home-02'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-6 col-md-12'>
              <div class='theme-section-title'>
                <span class='subtitle'>ABOUT US & EXPERIENCE</span>
                <h4 class='title'>
                  Moving beyond product innovation to gain a competitive advantage
                  <svg
                    class='title-shape style-01'
                    width='355'
                    height='15'
                    viewBox='0 0 355 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      class='path'
                      d='M351.66 12.6362C187.865 -6.32755 49.6478 6.37132 3.41142 12.6362'
                      stroke='#764AF1'
                      stroke-width='3'
                      stroke-linecap='square'
                    />
                    <path
                      class='path'
                      d='M351.66 13C187.865 -5.96378 49.6478 6.73509 3.41142 13'
                      stroke='#764AF1'
                      stroke-width='3'
                      stroke-linecap='square'
                    />
                    <path
                      class='path'
                      d='M2.5 5.5C168.5 2.0001 280.5 -1.49994 352.5 8.49985'
                      stroke='#FFC44E'
                      stroke-width='3'
                      stroke-linecap='square'
                    />
                  </svg>
                </h4>
              </div>
              <div class='about-content-wrap'>
                <p>
                  The education consultancy in Mangalore helps students and their families navigate
                  the complex world of higher education. They offer college and university
                  counseling, application support, financial aid advice and other services,
                  including test preparation courses, career counseling, and language classes. The
                  team of experienced professionals is passionate about student success and offers
                  tailored support to help students reach their full potential. With a focus on
                  student success, the education consultancy is a valuable resource for those
                  seeking higher education in Mangalore
                </p>

                <span class='core'>Core strength</span>
              </div>
              <div class='counter-section-inner style-a'>
                <div class='single-counterup color-01'>
                  <div class='content-wrap'>
                    <div class='odo-area'>
                      <h3 class='odometer odo-title' data-odometer-final='15'>
                        5
                      </h3>
                    </div>
                    <div class='content'>
                      <h6 class='subtitle'>Years Experience</h6>
                    </div>
                  </div>
                </div>
                <div class='single-counterup color-03'>
                  <div class='content-wrap'>
                    <div class='odo-area'>
                      <h3 class='odometer odo-title style-02' data-odometer-final='96'>
                        100 %
                      </h3>
                      {/* <h3 class='title'>%</h3> */}
                    </div>
                    <div class='content'>
                      <h6 class='subtitle'>Admission success</h6>
                    </div>
                  </div>
                </div>
              </div>
              <Link to='contact'>
                <div class='btn-wrap'>
                  <a href='' class='btn-common fill-btn'>
                    Get Free Consultation
                  </a>
                </div>
              </Link>
            </div>
            <div class='col-lg-6 col-md-12'>
              <div class='thumbnail'>
                <img src={aboutus} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            About Section Area End Here
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->

        <!--~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            Category Section Area Start Here
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~--> */}
      <section class='category-section-area'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='section-title-wrapper d-flex justify-content-between'>
                <div class='theme-section-title'>
                  <span class='subtitle'>CATEGORIES</span>
                  <h4 class='title'>Popular Collage</h4>
                </div>
                <ul class='nav nav-pills'>
                  <li class='nav-item'>
                    <Link to='college'>
                      <a href='#' class='nav-link' data-bs-toggle='pill' data-bs-target='#collage'>
                        See Colleges
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class='tab-content'>
            <div class='tab-pane fade show active' id='discipline'>
              <div class='destination-items-wrap'>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={foresty} alt='' />
                  </div>
                  <h6 class='name'>
                    Agriculture & <br /> Foresty
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={science} alt='' />
                  </div>
                  <h6 class='name'>
                    science <br /> & professional
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={art} alt='' />
                  </div>
                  <h6 class='name'>
                    Art, Design & <br /> Culture
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={business} alt='' />
                  </div>
                  <h6 class='name'>
                    Business & <br /> Management
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={pc} alt='' />
                  </div>
                  <h6 class='name'>
                    Computer <br /> Science & IT
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={book} alt='' />
                  </div>
                  <h6 class='name'>
                    Education & <br /> Training
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={click} alt='' />
                  </div>
                  <h6 class='name'>
                    Engeneering & <br /> Technology
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={french} alt='' />
                  </div>
                  <h6 class='name'>
                    Hopitality & <br /> Sports
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={news} alt='' />
                  </div>
                  <h6 class='name'>
                    Journalism & <br /> Media
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={firstAid} alt='' />
                  </div>
                  <h6 class='name'>
                    Medicine & <br /> Health
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={law} alt='' />
                  </div>
                  <h6 class='name'>Law</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={enverment} alt='' />
                  </div>
                  <h6 class='name'>Social Science</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={humanity} alt='' />
                  </div>
                  <h6 class='name'>Humanities</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src={recicle} alt='' />
                  </div>
                  <h6 class='name'>
                    Enviromental <br /> Studies
                  </h6>
                </div>
              </div>
            </div>
            <div class='tab-pane fade' id='collage'>
              <div class='destination-items-wrap'>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/french-fry.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Hopitality & <br /> Sports
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/news-paper.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Journalism & <br /> Media
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/first-aid-kit.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Medicine & <br /> Health
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/law.png' alt='' />
                  </div>
                  <h6 class='name'>Law</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/enverment.png' alt='' />
                  </div>
                  <h6 class='name'>Social Science</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/humanity.png' alt='' />
                  </div>
                  <h6 class='name'>Humanities</h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/recicle.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Enviromental <br /> Studies
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/foresty.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Agriculture & <br /> Foresty
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/science.png' alt='' />
                  </div>
                  <h6 class='name'>
                    science <br /> & professional
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/art.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Art, Design & <br /> Culture
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/business.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Business & <br /> Management
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/pc.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Computer <br /> Science & IT
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/book.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Education & <br /> Training
                  </h6>
                </div>
                <div class='destination-single-item style-01'>
                  <div class='thumbnail'>
                    <img src='assets/img/sections/desipline/click.png' alt='' />
                  </div>
                  <h6 class='name'>
                    Engeneering & <br /> Technology
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='destination-section style-01 margin-top-110 instruction'>
        <div class='container custom-container-01'>
          <div class='row justify-content-center'>
            <div class='col-lg-12'>
              <div class='theme-section-title desktop-center text-center'>
                <span class='subtitle'>STEPS</span>
                <h4 class='title'>Steps to Get your Destination</h4>
              </div>
            </div>
          </div>
          <div class='destination-items-wrap'>
            <div class='destination-single-item style-02'>
              <div class='thumbnail'>
                <img src={stepOne} alt='' />
              </div>
              <h6 class='name'>
                Identify course <br /> collage
              </h6>
            </div>
            <div class='destination-single-item style-02'>
              <div class='thumbnail'>
                <img src={stepTwo} alt='' />
              </div>
              <h6 class='name'>
                science <br /> & professional
              </h6>
            </div>
            <div class='destination-single-item style-02'>
              <div class='thumbnail'>
                <img src={stepThree} alt='' />
              </div>
              <h6 class='name'>
                Art, Design & <br /> Culture
              </h6>
            </div>
            <div class='destination-single-item style-02'>
              <div class='thumbnail'>
                <img src={stepFour} alt='' />
              </div>
              <h6 class='name'>
                Business & <br /> Management
              </h6>
            </div>
            <div class='destination-single-item style-02'>
              <div class='thumbnail'>
                <img src={stepFive} alt='' />
              </div>
              <h6 class='name'>
                Computer <br /> Science & IT
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
