import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Branches = () => {
  const location = useLocation();
  let branch = location.state.branches;
  let course = location.state.courseName;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };
  return (
    <div class='all-course-wrapper single-page-section-top-space single-page-section-bottom-space'>
      <div class='breadcrumb-wrap style-01'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='breadcrumb-content'>
                <h2 class='title'>{course}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class='course-area-wrapper'>
        <div class='container custom-container-01'>
          <div class='row column-gap-50'>
            {branch.map(item => (
              <div class='col-sm-6 col-md-4 col-xl-3'>
                <div class='single-course-item'>
                  <div class='thumbnail zoom-in'>
                    <div
                      class='background-image'
                      // style={{ 'background-image': `url(https://via.placeholder.com/100x100)` }}
                    >
                      <img key={item.id} src={item.img_url} />
                    </div>
                  </div>
                  <div class='contact'>
                    <h3 class='title' onClick={handleClick}>
                      <a href=''>{item.branch}</a>
                    </h3>
                  </div>

                  <div class='hover-option'>
                    <div class='contact'>
                      <h3 class='title' onClick={handleClick}>
                        <a href=''>{item.branch}</a>
                      </h3>
                      <p>{item.description}</p>
                      <p class='paragraph' onClick={handleClick}>
                        <a href=''> Know More</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Branches;
