import React, { useEffect } from 'react';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBarComponent from './components/Nav/NavBarComponent';
import Footer from './components/Footer/Footer';
import './assets/css/animate.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/jquery.rprogessbar.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/nice-select.css';
import './assets/css/odometer.css';
import './assets/css/slick.css';
import './assets/css/style.css';
import './assets/css/swiper.min.css';
import './assets/css/responsive.css';
import './App.scss';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Courses from './pages/Courses';
import Branches from './components/Courses/Branches';
import College from './pages/College';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <main class='page-wrapper'>
      <Router>
        <ScrollToTop />
        <NavBarComponent />

        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='about' element={<AboutUs />} />
          <Route exact path='courses' element={<Courses />} />
          <Route exact path='college' element={<College />} />
          <Route exact path='contact' element={<ContactUs />} />
          <Route exact path='branches' element={<Branches />} />
        </Routes>
        <Footer />
      </Router>
    </main>
  );
}

export default App;
