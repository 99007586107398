import mech from '../assets/courses/Engg/mechanicalEngg.jpg';
import ec from '../assets/courses/Engg/e&c.jpg';
import eee from '../assets/courses/Engg/eee.jpg';
import cs from '../assets/courses/Engg/computer science.jpg';
import is from '../assets/courses/Engg/is.jpg';
import ae from '../assets/courses/Engg/aeronatical.jpg';
import civil from '../assets/courses/Engg/civilengg.jpg';
import ai from '../assets/courses/Engg/ai.png';
import ot from '../assets/courses/Diploma/DIPLOMA IN OT.jpg';
import ray from '../assets/courses/Diploma/DIPLOMA IN X-RAY.jpg';
import dmlt from '../assets/courses/Diploma/DMLT- DIPLOMA IN MEDICAL LABORATORY TECHNOLOGY.jpeg';
import gn from '../assets/courses/Diploma/GENERAL NURSING AND MID WIFERY.png';
import pc from '../assets/courses/Diploma/PC.BSC NURSING.jpg';
import bams from '../assets/courses/Medical/BACHELOR OF AYURVEDIC MEDICINE AND SURGERY.jpg';
import bds from '../assets/courses/Medical/BACHELOR OF DENTAL SURGERY.jpg';
import bhms from '../assets/courses/Medical/BACHELOR OF HOMEOPATHIC MEDICINE AND SURGERY.png';
import mbbs from '../assets/courses/Medical/BACHELOR OF MEDICINE AND BACHELOR OF SURGERY.jpg';
import bnys from '../assets/courses/Medical/BACHELOR OF NATUROPATHY AND YOGIC SCIENCE.jpg';
import business from '../assets/courses/UG courses/BBA BUSINESS ANALYTICS , ENTREPRENEURSHIP AND INTERNATIONAL.jpeg';
import logis from '../assets/courses/UG courses/BBA LOGISTICS AND PORT MANAGEMENT.jpeg';
import avi from '../assets/courses/UG courses/BBA WITH AVIATION AND LOGISTICS.jpg';
import stock from '../assets/courses/UG courses/BBA WITH STOCK MARKET MANAGEMENT.jpeg';
import hotel from '../assets/courses/UG courses/HOTEL MANAGEMENT.jpeg';
import robo from '../assets/courses/UG courses/BCA ROBOTICS, MACHINE LEARNING AND.jpg';
import data from '../assets/courses/UG courses/BCA WITH BIG DATA ANALYSIS.jpg';
import acca from '../assets/courses/UG courses/BCOM ACCA.jpg';
import cma from '../assets/courses/UG courses/BCOM CMA (CERTIFIED MANAGEMENT ACCOUNTANT).jpg';
import fin from '../assets/courses/UG courses/BCOM FINANCE, TAXATION AND AUDITING.jpg';
import nut from '../assets/courses/UG courses/BSC FOOD AND NUTRITION.jpg';
import food from '../assets/courses/UG courses/BSC FOOD TECHNOLOGY.jpg';
import forins from '../assets/courses/UG courses/BSC FORENSIC SCIENCE.jpg';
import inter from '../assets/courses/UG courses/BSC INTERIOR DESIGN.jpg';
import cyber from '../assets/courses/UG courses/BSC-BCA IN CYBER FORENSIC, DATA ANALYTICS.jpg';
import bcomAvi from '../assets/courses/UG courses/BCOM AVIATION AND LOGISTICS.png';
import bhs from '../assets/courses/UG courses/BHS WITH AVIATION (TRAVEL AND TOURISM).png';
import fash from '../assets/courses/UG courses/BSC FASHION DESIGN.png';
import bbaBuss from '../assets/courses/UG courses/BBA WITH BUSINESS ANALYSIS, ENTREPRENEURSHIP AND.jpg';
import dpharm from '../assets/courses/UG Paramedical/D PHARM.jpeg';
import baslp from '../assets/courses/UG Paramedical/BASLP.png';
import micro from '../assets/courses/UG Paramedical/BSC MICROBIOLOGY.png';
import bpharm from '../assets/courses/UG Paramedical/B PHARM.jpg';
import phy from '../assets/courses/UG Paramedical/BACHELOR OF PHYSIOTHERAPY.jpg';
import hospit from '../assets/courses/UG Paramedical/BHA- HOSPITAL ADMINISTRATION.jpg';
import clinic from '../assets/courses/UG Paramedical/BSC CLINICAL PSYCHOLOGY.jpg';
import emergency from '../assets/courses/UG Paramedical/BSC EMERGENCY MEDICINE.jpg';
import mlt from '../assets/courses/UG Paramedical/BSC MLT.jpg';
import neuro from '../assets/courses/UG Paramedical/BSC NEURO SCIENCE.jpg';
import nursing from '../assets/courses/UG Paramedical/BSC NURSING.jpg';
import occ from '../assets/courses/UG Paramedical/BSC OCCUPATIONAL THERAPY.jpg';
import phyAss from '../assets/courses/UG Paramedical/BSC PHYSICIAN ASSISTANT.jpg';
import respi from '../assets/courses/UG Paramedical/BSC RESPIRATORY CARE TECHNOLOGY.jpg';
import prefu from '../assets/courses/UG Paramedical/BSc-Perfusion-Technology.jpg';
import cvt from '../assets/courses/UG Paramedical/CVT.jpg';
import mit from '../assets/courses/UG Paramedical/MIT.jpg';
import opto from '../assets/courses/UG Paramedical/OPTOMETRY.jpg';
import otPara from '../assets/courses/UG Paramedical/OT.jpg';
import publicHealth from '../assets/courses/UG Paramedical/PUBLIC HEALTH.jpg';
import renal from '../assets/courses/UG Paramedical/renal dialysis.jpg';

export const courseData = [
  {
    id: 1,
    course: 'Engineering',
    description:
      'Engineering is a branch of science and technology concerned with designing, building, and using engines, machines, and structures. It involves the application of mathematical and scientific principles to design and develop solutions to real-world problems. Some popular branches of engineering include mechanical, electrical, civil, computer science, and aerospace engineering.',
    img_url: is,
    branches: [
      {
        id: 1,
        branch: 'MECHANICAL ENGINEERING (M.E)',
        description:
          'MECHANICAL ENGINEERING is a branch of engineering that deals with the design, development, and production of machinery, tools, and mechanical systems. It covers a wide range of applications from small mechanical components to large-scale production systems.',
        img_url: mech,
      },
      {
        id: 2,
        branch: 'ELECTRONICS AND COMMUNICATIONS ENGINEERING (ECE)',
        description:
          'ELECTRONICS AND COMMUNICATIONS ENGINEERING is a branch of engineering that deals with the design and development of electronic systems and communication technologies. This includes the study of analog and digital circuits, communication systems, and computer networks.',
        img_url: ec,
      },
      {
        id: 3,
        branch: 'ELECTRONICS AND ELECTRICAL ENGINEERING (EEE)',
        description:
          'ELECTRONICS AND ELECTRICAL ENGINEERING is a branch of engineering that deals with the study and application of electricity, electronics, and electromagnetism. It encompasses areas such as electrical power systems, digital electronics, and communication systems.',
        img_url: eee,
      },
      {
        id: 4,
        branch: 'COMPUTER SCIENCE ENGINEERING (CS)',
        description:
          ' COMPUTER SCIENCE ENGINEERING is a branch of engineering that deals with the study of computers and computing systems. It covers a wide range of topics such as algorithms, programming languages, computer architecture, and software engineering.',
        img_url: cs,
      },
      {
        id: 5,
        branch: 'INFORMATION SCIENCE ENGINEERING (IS)',
        description:
          'INFORMATION SCIENCE ENGINEERING is a branch of engineering that deals with the study of information and its processes, including storage, retrieval, representation, and communication. It covers areas such as database management, data mining, and information security.',
        img_url: is,
      },
      {
        id: 6,
        branch: ' AERONAUTICAL ENGINEERING',
        description:
          'AERONAUTICAL ENGINEERING is a branch of engineering that deals with the design and development of aircraft and spacecraft. This includes the study of aerodynamics, propulsion systems, and flight mechanics.',
        img_url: ae,
      },
      {
        id: 7,
        branch: 'CIVIL ENGINEERING',
        description:
          'CIVIL ENGINEERING is a branch of engineering that deals with the design, construction, and maintenance of the built environment, including structures such as buildings, roads, bridges, and water supply systems.',
        img_url: civil,
      },
      {
        id: 8,
        branch: 'ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING',
        description:
          'ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING are interdisciplinary fields that deal with the development of algorithms and models that enable machines to perform tasks that normally require human intelligence, such as visual perception, speech recognition, and decision-making.',
        img_url: ai,
      },
    ],
  },
  {
    id: 2,
    course: 'UG Courses',
    description:
      'UG courses refer to Undergraduate courses that typically last for 3-4 years and are offered by colleges and universities. They provide students with a solid foundation in their chosen field and help them build the skills and knowledge needed to pursue a successful career. Some popular UG courses include B.A., B.Sc., B.Com., B.Tech., and B.E.',
    img_url: business,
    branches: [
      {
        id: 1,
        branch: 'BBA with Stock Market Management',
        description:
          'BBA with Stock Market Management - This program focuses on providing students with knowledge and skills related to stock market analysis, investment strategies, and stock market management.',
        img_url: stock,
      },
      {
        id: 2,
        branch: 'BBA with Aviation and Logistics',
        description:
          'BBA with Aviation and Logistics - This program aims to provide students with a comprehensive understanding of the aviation and logistics industry, including air transportation management, air cargo management, and airport operations.',
        img_url: avi,
      },
      {
        id: 3,
        branch: 'BBA with Business Analysis, Entrepreneurship and International Business',
        description:
          'BBA with Business Analysis, Entrepreneurship and International Business - This program focuses on providing students with the knowledge and skills needed to analyze business trends, start and manage their own businesses, and understand international business practices.',
        img_url: business,
      },
      {
        id: 4,
        branch: 'BCom Aviation and Logistics',
        description:
          'BCom Aviation and Logistics - This program provides students with a comprehensive understanding of the aviation and logistics industry, including air transportation management, air cargo management, and airport operations.',
        img_url: bcomAvi,
      },
      {
        id: 5,
        branch: 'BBA Logistics and Port Management',
        description:
          'BBA Logistics and Port Management - This program focuses on providing students with knowledge and skills related to logistics management, transportation management, and port operations.',
        img_url: logis,
      },
      {
        id: 6,
        branch: 'BCom Finance, Taxation and Auditing',
        description:
          'BCom Finance, Taxation and Auditing - This program provides students with a comprehensive understanding of finance, taxation, and auditing practices, including financial analysis, accounting, and tax planning.',
        img_url: fin,
      },
      {
        id: 7,
        branch: 'BCom ACCA',
        description:
          'BCom ACCA - This program prepares students for the ACCA (Association of Chartered Certified Accountants) examination, which is a globally recognized professional qualification in finance and accounting.',
        img_url: acca,
      },
      {
        id: 8,
        branch: 'BCom CMA (Certified Management Accountant) ',
        description:
          'BCom CMA (Certified Management Accountant) - This program prepares students for the CMA (Certified Management Accountant) certification, which is a professional designation for management accountants and financial managers.',
        img_url: cma,
      },
      {
        id: 9,
        branch: 'BCA with Big Data Analysis',
        description:
          'BCA with Big Data Analysis - This program focuses on providing students with the knowledge and skills needed to analyze and interpret large amounts of data using big data tools and technologies.',
        img_url: data,
      },
      {
        id: 10,
        branch: 'BHS with Aviation (Travel and Tourism)',
        description:
          'BHS with Aviation (Travel and Tourism) - This program provides students with a comprehensive understanding of the travel and tourism industry, including air transportation management, air cargo management, and airport operations.',
        img_url: bhs,
      },
      {
        id: 11,
        branch: 'BSc Food and Nutrition',
        description:
          'BSc Food and Nutrition - This program provides students with a comprehensive understanding of food and nutrition, including the science of nutrition, food science, and dietetics.',
        img_url: nut,
      },
      {
        id: 12,
        branch: 'BSc Forensic Science',
        description:
          'BSc Forensic Science - This program provides students with a comprehensive understanding of forensic science, including crime scene investigation, evidence analysis, and laboratory techniques.',
        img_url: forins,
      },
      {
        id: 13,
        branch: 'BHM - Hotel Management',
        description:
          'BHM - Hotel Management - This program focuses on providing students with knowledge and skills related to the hotel and hospitality industry, including hotel operations, front office management, and food and beverage management.',
        img_url: hotel,
      },
      {
        id: 14,
        branch: 'BSc Interior Design',
        description:
          'BSc Interior Design - This program focuses on providing students with the knowledge and skills needed to design and create functional and aesthetically pleasing interior spaces.',
        img_url: inter,
      },
      {
        id: 15,
        branch: 'BSc Fashion Design',
        description:
          'BSc Fashion Design - This program focuses on providing students with the knowledge and skills needed to design and create clothing and fashion accessories.',
        img_url: fash,
      },
      {
        id: 16,
        branch: 'BSc Food Technology',
        description:
          'BSc Food Technology - This program provides students with a comprehensive understanding of food technology, including food science, food processing, and food safety.',
        img_url: food,
      },
      {
        id: 17,
        branch: 'BSc/BCA in Cyber Forensic, Data Analytics and Cyber Security with IBM',
        description:
          'BSc/BCA in Cyber Forensic, Data Analytics and Cyber Security with IBM - This program focuses on providing students with the knowledge and skills needed to protect against cyber attacks and analyze data using IBM tools and technologies.',
        img_url: cyber,
      },
      {
        id: 18,
        branch: 'BCA Robotics, Machine Learning and Artificial Intelligence with Microsoft',
        description:
          'BCA Robotics, Machine Learning and Artificial Intelligence with Microsoft - This program focuses on providing students with the knowledge and skills needed to develop robotics, machine learning, and artificial intelligence applications using Microsoft tools and technologies.',
        img_url: robo,
      },
      {
        id: 19,
        branch: 'BBA Business Analytics, Entrepreneurship and International Business with IBM ',
        description:
          'BBA Business Analytics, Entrepreneurship and International Business with IBM - This program focuses on providing students with the knowledge and skills needed to analyze business trends, start and manage their own businesses, and understand international business practices using IBM tools and technologies.',
        img_url: bbaBuss,
      },
    ],
  },
  {
    id: 3,
    course: 'UG Paramedical',
    description:
      'UG Paramedical courses are undergraduate courses that prepare students for careers in the healthcare sector. These courses provide students with hands-on training and theoretical knowledge in areas such as anatomy, physiology, pharmacology, and patient care. Some popular UG paramedical courses include B.Sc. in Nursing, B.Pharm, B.Optom, and B.PT.',
    img_url: otPara,
    branches: [
      {
        id: 1,
        branch: 'BSC Medical Lab Technology (MLT)',
        description:
          'BSC Medical Lab Technology (MLT): This course focuses on teaching students about laboratory diagnostic procedures and techniques used in the medical field.',
        img_url: mlt,
      },
      {
        id: 2,
        branch: 'BSC Anaesthesia and OT Technology (OT)',
        description:
          'BSC Anaesthesia and OT Technology (OT): This course deals with the use of anesthesia and OT techniques in medical procedures.',
        img_url: otPara,
      },
      {
        id: 3,
        branch: 'BSC Cardio Vascular Technology (CVT)',
        description:
          'BSC Cardio Vascular Technology (CVT): This course focuses on the use of technology in diagnosing and treating cardiovascular diseases.',
        img_url: cvt,
      },
      {
        id: 4,
        branch: 'BSC Medical Imaging Technology (MIT)',
        description:
          'BSC Medical Imaging Technology (MIT): This course deals with the use of medical imaging techniques, such as X-rays, MRI, CT scans, etc.',
        img_url: mit,
      },
      {
        id: 5,
        branch: 'BSC Perfusion Technology',
        description:
          'BSC Perfusion Technology: This course deals with the use of technology in perfusion, the process of maintaining blood circulation and oxygen supply to organs.',
        img_url: prefu,
      },
      {
        id: 6,
        branch: 'BSC Renal Dialysis Technology',
        description:
          'BSC Renal Dialysis Technology: This course deals with the use of technology in renal dialysis, a medical procedure to remove waste from the blood of patients with kidney failure.',
        img_url: renal,
      },
      {
        id: 7,
        branch: 'BSC Optometry',
        description:
          'BSC Optometry: This course deals with the study of the eyes and vision and how to diagnose and treat eye disorders.',
        img_url: opto,
      },
      {
        id: 8,
        branch: 'BSC Neuro Science',
        description:
          'BSC Neuro Science: This course deals with the study of the structure and function of the nervous system.',
        img_url: neuro,
      },
      {
        id: 9,
        branch: 'BSC Physician Assistant',
        description:
          'BSC Physician Assistant: This course focuses on preparing students to work as physician assistants, who provide medical care under the supervision of a doctor.',
        img_url: phyAss,
      },
      {
        id: 10,
        branch: 'BSC Emergency Medicine',
        description:
          'BSC Emergency Medicine: This course focuses on teaching students about emergency medical procedures and treatments.',
        img_url: emergency,
      },
      {
        id: 11,
        branch: 'BSC Clinical Psychology',
        description:
          'BSC Clinical Psychology: This course focuses on the study of mental health and psychological disorders and how to diagnose and treat them.',
        img_url: clinic,
      },
      {
        id: 12,
        branch: 'BSC Occupational Therapy',
        description:
          'BSC Occupational Therapy: This course focuses on the use of therapeutic techniques to help patients recover from physical or mental illnesses.',
        img_url: occ,
      },
      {
        id: 13,
        branch: 'BSC Respiratory Care Technology',
        description:
          'BSC Respiratory Care Technology: This course deals with the use of technology in respiratory care, such as ventilators, oxygen therapy, etc.',
        img_url: respi,
      },
      {
        id: 14,
        branch: 'BSC Microbiology',
        description:
          'BSC Microbiology: This course deals with the study of microorganisms and their impact on human health and the environment.',
        img_url: micro,
      },
      {
        id: 15,
        branch: 'B Pharm',
        description:
          'B Pharm: This course deals with the study of pharmacology and pharmaceuticals.',
        img_url: bpharm,
      },
      {
        id: 16,
        branch: 'D Pharm',
        description:
          'D Pharm: This course is a diploma in pharmacy and prepares students to work as pharmacists.',
        img_url: dpharm,
      },
      {
        id: 17,
        branch: 'BPT ',
        description:
          'BPT - Bachelor of Physiotherapy: This course deals with the use of physical therapy techniques to help patients recover from physical injuries or disabilities.',
        img_url: phy,
      },
      {
        id: 18,
        branch: 'BASLP',
        description:
          'BASLP - Bachelor of Audiology and Speech Language Pathology: This course focuses on the study of hearing and speech disorders and how to diagnose and treat them.',
        img_url: baslp,
      },
      {
        id: 19,
        branch: 'BHA',
        description:
          'BHA - Hospital Administration: This course deals with the management of hospitals, including finance, human resources, and patient care.',
        img_url: hospit,
      },
      {
        id: 20,
        branch: 'BPH',
        description:
          'BPH - Public Health: This course focuses on the study of public health, including disease prevention, health promotion, and healthcare delivery.',
        img_url: publicHealth,
      },
      {
        id: 21,
        branch: 'BSC Nursing',
        description:
          'BSC Nursing: This course prepares students to work as registered nurses and focuses on patient care and nursing practices.',
        img_url: nursing,
      },
    ],
  },
  {
    id: 4,
    course: 'Medical',
    description:
      'Medical is a branch of science concerned with the study of human anatomy, physiology, and pathology. It deals with the diagnosis, treatment, and prevention of diseases and illnesses. Medical professionals such as doctors, nurses, and paramedics use their knowledge and skills to provide healthcare services to patients.',
    img_url: mbbs,
    branches: [
      {
        id: 1,
        branch: 'MBBS – BACHELOR OF MEDICINE AND BACHELOR OF SURGERY',
        description:
          'MBBS (Bachelor of Medicine and Bachelor of Surgery) is the most common medical degree in India, and it trains students in all aspects of modern medicine, including anatomy, physiology, pathology, pharmacology, and surgery.',
        img_url: mbbs,
      },
      {
        id: 2,
        branch: 'BDS – BACHELOR OF DENTAL SURGERY',
        description:
          'BDS (Bachelor of Dental Surgery) is a dental degree that trains students in oral health, diagnosis, and treatment of oral diseases, and other dental procedures.',
        img_url: bds,
      },
      {
        id: 3,
        branch: ' BAMS – BACHELOR OF AYURVEDIC MEDICINE AND SURGERY',
        description:
          'BAMS (Bachelor of Ayurvedic Medicine and Surgery) trains students in Ayurveda, an ancient system of medicine that originated in India and uses natural remedies, herbs, and massages to treat various illnesses.',
        img_url: bams,
      },
      {
        id: 4,
        branch: 'BHMS – BACHELOR OF HOMEOPATHIC MEDICINE AND SURGERY',
        description:
          "BHMS (Bachelor of Homeopathic Medicine and Surgery) trains students in homeopathy, a system of medicine that uses natural substances in small doses to stimulate the body's healing mechanisms.",
        img_url: bhms,
      },
      {
        id: 5,
        branch: 'BNYS – BACHELOR OF NATUROPATHY AND YOGIC SCIENCE',
        description:
          "BNYS (Bachelor of Naturopathy and Yogic Science) trains students in naturopathy, a system of medicine that utilizes the power of nature and the body's own healing mechanisms to promote wellness and treat various illnesses.",
        img_url: bnys,
      },
    ],
  },
  {
    id: 5,
    course: 'Diploma',
    description:
      'A diploma is a certificate awarded by a college or university to a student who has completed a course of study in a specific field. It typically takes less time to complete than a degree program and is designed to provide students with the practical skills and knowledge they need to enter the workforce quickly. Some popular diploma courses include Diploma in Engineering, Diploma in Nursing, and Diploma in Education.',
    img_url: dmlt,
    branches: [
      {
        id: 1,
        branch: 'GNM – GENERAL NURSING AND MID WIFERY',
        description:
          'GNM (General Nursing and Midwifery) is a three-year diploma course that trains students in the basic principles of nursing and midwifery, including patient care, administration of medicines, and assisting in surgeries.',
        img_url: gn,
      },
      {
        id: 2,
        branch: 'PC.BSC NURSING',
        description:
          'PC BSc Nursing is a four-year bachelors degree program that provides comprehensive training in nursing, including anatomy, physiology, pharmacology, and patient care.',
        img_url: pc,
      },
      {
        id: 3,
        branch: 'DMLT- DIPLOMA IN MEDICAL LABORATORY TECHNOLOGY',
        description:
          'DMLT (Diploma in Medical Laboratory Technology) is a two-year diploma course that trains students in the collection, examination, and analysis of laboratory samples to diagnose and treat various diseases.',
        img_url: dmlt,
      },
      {
        id: 4,
        branch: 'DIPLOMA IN X-RAY',
        description:
          'Diploma in X-Ray Technology is a two-year diploma course that trains students in the use of X-rays to diagnose and treat various medical conditions, as well as the safe handling and maintenance of X-ray equipment.',
        img_url: ray,
      },
      {
        id: 5,
        branch: 'DIPLOMA IN OT',
        description:
          'Diploma in Operation Theatre Technology is a two-year diploma course that trains students in the preparation and maintenance of operating rooms, as well as the use of surgical instruments and equipment during surgeries.',
        img_url: ot,
      },
    ],
  },
  {
    id: 6,
    course: 'PG Courses',
    description:
      'PG courses refer to Postgraduate courses that typically last for 1-2 years and are offered by colleges and universities. They are designed for students who have completed their undergraduate studies and want to continue their education in a specialized field. PG courses provide students with advanced knowledge and skills in their chosen area of study, which can lead to better career opportunities and higher salaries. Some popular PG courses include M.A., M.Sc., M.Com., M.Tech., M.B.A., and M.Ed.',
    img_url: cs,
  },
];
