import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/SMEC-Logo.png';
import './Navbar.scss';

const NavBarComponent = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar expanded={expanded} expand='lg'>
      <Navbar.Brand href='/'>
        <img src={logo} width='100' height='60' className='d-inline-block align-top' alt='Logo' />
      </Navbar.Brand>
      <Navbar.Toggle onClick={handleToggle} aria-controls='navbar-nav' />
      <Navbar.Collapse id='navbar-nav'>
        <button className='closeBtn' onClick={handleToggle}>
          <i className='fas fa-times' />
        </button>
        <Nav className='ml-auto'>
          <Link to='/' className='nav-link' activeClassName='active-link' onClick={handleToggle}>
            Home
            <span className='underline' />
          </Link>
          <Link to='about' className='nav-link' activeClassName='active-link' onClick={handleToggle}>
            About Us
            <span className='underline' />
          </Link>
          <Link to='courses' className='nav-link' activeClassName='active-link'onClick={handleToggle} >
            Courses
            <span className='underline' />
          </Link>
          <Link to='college' className='nav-link' activeClassName='active-link' onClick={handleToggle}>
            Colleges
            <span className='underline' />
          </Link>
          <Link to='contact' className='nav-link' activeClassName='active-link' onClick={handleToggle}>
            Contact Us
            <span className='underline' />
          </Link>
          {/* <Link to='https://courses.smart-root.com/' className='nav-link' activeClassName='active-link'  target='_blank'>
            E-Learning
            <span className='underline' />
          </Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarComponent;
