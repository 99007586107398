import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/SMEC-Logo.png';
import './Footer.scss';

const Footer = () => {
  const handlePhoneClick = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.location.href = 'tel:+918904293697';
    }
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:smec@smart-root.com';
  };
  return (
    <footer class='footer-area style-01'>
      <div class='footer-top'>
        <div class='container custom-container-01'>
          <div class='row'></div>
          <div class='footer-middle'>
            <div class='row'>
              <div class='col-lg-2 col-md-6 col-sm-6'>
                <div className='footerImg'>
                  <img src={logo} alt='logo' />
                </div>
              </div>
              {/* <div class='col-lg-2 col-md-6 col-sm-6'>
                <div class='footer-widget widget widget_nav_menu'>
                  <h4 class='widget-headline'>Site Highlight</h4>
                  <ul>
                    <li>
                      <a href='#'>Student videos</a>
                    </li>
                    <li>
                      <a href='#'>Photo gallery</a>
                    </li>
                    <li>
                      <a href='#'>TSC prospectus</a>
                    </li>
                    <li>
                      <a href='#'>Student newsletter</a>
                    </li>
                    <li>
                      <a href='#'>Student portal</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div class='col-lg-2 col-md-6 col-sm-6'>
                <div class='footer-widget widget widget_nav_menu'>
                  <h4 class='widget-headline'>Main Links</h4>
                  <ul>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to='about'>About Us</Link>
                    </li>
                    <li class='menu-item-has-children'>
                      <Link to='courses'>Courses</Link>
                    </li>
                    <li class='menu-item-has-children'>
                      <Link to='college'>Colleges</Link>
                    </li>
                    <li>
                      <Link to='contact'>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class='col-lg-2 col-md-6 col-sm-6'>
                <div className='footer-widget widget widget_nav_menu'>
                  <h4 className='widget-headline'>Contact Us</h4>
                  <ul>
                    <li class='menu-item-has-children' onClick={handlePhoneClick}>
                      <Link to=''>(+91) 8904293697</Link>
                    </li>
                    <li class='menu-item-has-children'>08243575400</li>
                    <li class='menu-item-has-children' onClick={handleEmailClick}>
                      <Link to=''> smec@smart-root.com</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class='col-lg-2 col-md-6 col-sm-6'>
                <div class='footer-widget widget widget_nav_menu'>
                  <h4 class='widget-headline'>Location</h4>
                  <ul class='contact_info_list'>
                    <li class='single-info-item'>
                      <div class='icon'>
                        <img src='assets/img/icon/location-02.png' alt='' />
                      </div>
                      <div class='details'>
                        {/* Door No: 3-E-16-1370/2(1) */}
                        Shop Number: 34
                        <br/>Address: 3rd floor, City Ligh Building, Next to Mandovi Motors, Milagris, Hampankatta, Manglore - 575001 
                      </div>
                    </li>
                    <li class='single-info-item'>
                      <div class='icon me-4'>
                        <img src='assets/img/icon/edu-award.png' alt='' />
                      </div>
                      <div class='icon'>
                        <img src='assets/img/icon/iso.png' alt='' />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='copyright-area'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='copyright-area-inner'>
                <p>
                  © 2023 SMEC. All rights reserved | Developed By <span>Adarsh S Shetty</span>
                </p>
                {/* <div class='footer-social-area'>
                  <ul class='social-icon-02'>
                    <li>
                      <a href='#0'>
                        <i class='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li>
                      <a href='#0'>
                        <i class='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a href='#0'>
                        <i class='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
