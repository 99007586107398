import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { colleges } from '../data/colleges';

const College = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };
  return (
    <div class='all-course-wrapper single-page-section-top-space single-page-section-bottom-space'>
      <div class='breadcrumb-wrap style-01'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='breadcrumb-content'>
                <h2 class='title'>Colleges</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class='course-area-wrapper'>
        <div class='container custom-container-01'>
          <div class='row column-gap-50'>
            {colleges.map(item => (
              <div class='col-sm-6 col-md-4 col-xl-3'>
                <div class='single-course-item'>
                  <div class='thumbnail zoom-in'>
                    <div
                      class='background-image'
                      style={{ 'background-image': 'url(assets/img/course/18.png);' }}
                    >
                      <img src={item.img_url} alt={item.name} />
                    </div>
                  </div>
                  <div class='contact'>
                    <h3 class='title' onClick={handleClick}>
                      <a>{item.name}</a>
                    </h3>
                    <p>{item.sub_title}</p>
                  </div>

                  <div class='hover-option'>
                    <div class='contact'>
                      <h3 class='title' onClick={handleClick}>
                        <a onClick={handleClick}>{item.name}</a>
                      </h3>
                      <p>{item.description}</p>
                      <p class='paragraph' onClick={handleClick}>
                        Know More
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default College;
