import React from 'react';
import engineering from '../assets/img/course-list/01.jpg';
import { courseData } from '../data/courses';
import { useNavigate } from 'react-router-dom';

const Courses = () => {
  const navigate = useNavigate();
  const handleClick = id => {
    const selectedBranch = courseData.find(item => item.id === id).branches;
    const course = courseData.find(item => item.id === id).course;
    navigate('/branches', { state: { branches: selectedBranch, courseName: course } });
  };

  return (
    <div class='all-course-wrapper single-page-section-top-space single-page-section-bottom-space'>
      <div class='breadcrumb-wrap style-01'>
        <div class='container custom-container-01'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='breadcrumb-content'>
                <h2 class='title'>Courses that we offer</h2>
                <p class='details'>
                  We have professional alliance's with leading Universities and Colleges around
                  Karnataka.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {courseData.map(item => (
          <div class='course-details-wrapper single-page-section-top-space single-page-section-bottom-space'>
            <div class='container custom-container-01'>
              <div class='row'>
                <div class='col-lg-7 col-xl-8'>
                  <div class='breadcrumb-wrap style-01'>
                    <div class='row'>
                      <div class='col-lg-12'>
                        <div class='breadcrumb-content'>
                          <h2 class='title'>{item.course}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='course-derails-inner'>
                    <div class='course-description-wrap'>
                      <h3 class='details-title'>Course Description</h3>
                      <p class='tutorial-details paragraph'>{item.description}</p>
                    </div>
                  </div>
                </div>

                <div class='col-md-7 col-lg-5 col-xl-4'>
                  <div class='course-as-product-wrap'>
                    <div class='thumbnail'>
                      <img src={item.img_url} alt='' />
                    </div>
                    <div class='content'>
                      <div class='btn-wrap' onClick={() => handleClick(item.id)}>
                        <a class='btn-common add-to-cart btn-active'>Know More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
